<template>
  <div
    class="pointer-events-auto relative flex size-full max-h-full flex-col overflow-hidden rounded-lg bg-clip-padding outline-0"
  >
    <div
      class="z-10 flex shrink-0 items-center justify-between rounded-t-lg p-4 px-6"
      :class="{
        'border-b border-b-gray-200 bg-gray-100 dark:border-b-gray-600 dark:bg-gray-900': !inline,
      }"
    >
      <h2
        v-if="title"
        size="h2"
        class="text-lg font-bold"
      >
        {{ title }}
      </h2>

      <BaseIcon
        v-if="!hideClose"
        icon="fal fa-xmark"
        class="ml-auto cursor-pointer text-lg"
        @click="emit('close')"
      />
    </div>

    <div
      class="relative shrink grow basis-auto overflow-y-auto p-6"
      :class="{
        '-mt-10': inline,
      }"
    >
      <slot />
    </div>

    <div
      v-if="$slots.footer"
      class="rounded-b-lg p-4 pt-2 text-right"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  title?: string
  hideClose?: boolean
  inline?: boolean
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()
</script>
